



import { Component, Emit, Vue, Watch } from 'vue-property-decorator';
import { CourseResponseCourses } from '../../types/typescript-axios/api';
import VueRouter, {Route} from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import Breadcrumb from '../components/contents/breadcrumb/Breadcrumb.vue';
import CourseList from '../components/contents/course/CourseList.vue';
import CourseCardSmall from '../components/contents/course/CourseCardSmall.vue';
import Error from '../components/contents/Error.vue';
import store from '@/store';
import { sortCourseList } from '../plugins/helper';
import { params } from 'vee-validate/dist/types/rules/alpha';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

interface SelectOptions {
  text: string;
  value: string;
}

async function getDatas(to: Route) {
  let status: number = 200;
  const url: string = '/wp-json/moc/course';
  const articleUrl: string = url + '?slug=';
  const $to: any = to;
  const queryName: string = '';
  const contentsData = await axios.get<AxiosResponse>(url, {
    params: {
      status: 'accepting',
      output: 200,
      orderby: 'event_date',
      order: 'desc',
    },
  });
  status = contentsData.status;

  return {
    status,
    contentsData,
    queryName,
    $to,
  };
}

@Component({
  components: {
    Breadcrumb,
    CourseList,
    CourseCardSmall,
    Error,
  },
})
export default class Course extends Vue {
  private title: any = '';
  private info: any;
  private course: CourseResponseCourses[] = [];
  private closedCourse: CourseResponseCourses[] = [];
  private closedTotal: number = 0;
  private status = 200;
  private total = 0;
  private queryName: number = 0;
  private options: SelectOptions[] = [];
  private selectValue: any = '';
  private breadcrumb: BreadcrumbArr = [
    {
      title: 'HOME',
      link: '/',
    },
    {
      title: '講座情報',
      link: '',
    },
  ];

  get slideCourse(): CourseResponseCourses[] {
    return sortCourseList(this.course);
  }

  @Watch('selectValue')
  private async onSelectValueChange() {
    const closedData: any = await this.getClosedCourse(this.selectValue, -1);
    this.closedCourse = closedData.data.courses;
    this.closedTotal = closedData.data.total;
  }

  @Emit()
  private setCourse(value: any): void {
    this.course = value;
  }
  private handleSelect(): void {
    this.$router.push({ path: `${this.$route.path}?archives=${this.selectValue}#archives` });
  }

  private async beforeRouteEnter(to: Route, from: VueRouter, next: any) {
    const data: any = await getDatas(to);
    if (data.status === 200) {
      if (isNaN(Number(to.query.archives))) {
        store.commit('window/setTitle', '講座一覧 ' + process.env.VUE_APP_TITLE_SUFFIX);
      } else {
        store.commit('window/setTitle', to.query.archives + '年 講座一覧 ' + process.env.VUE_APP_TITLE_SUFFIX);
      }
      next((vm: {
        course: CourseResponseCourses[],
        queryName: number,
        total: number,
        status: number,
      }) => {
        vm.status = data.status;
        vm.course = data.contentsData.data.courses;
        vm.total = data.contentsData.data.total;
        vm.queryName = Number(data.$to.query.archives);
      });
    } else {
      store.commit('window/setTitle', data.status + ' Error 講座一覧 ' + process.env.VUE_APP_TITLE_SUFFIX);
      next((vm: {status: number}) => {
        vm.status = data.status;
      });
    }
  }

  private async getClosedCourse(year: number, output: number): Promise<AxiosResponse<any>> {
    const url: string = '/wp-json/moc/course';
    return await axios.get(url, {
      params: {
        status: 'closed',
        term_start: `${year}-01-01`,
        term_end: `${year}-12-31`,
        orderby: 'event_date',
        order: 'DESC',
        output,
      },
    });
  }

  @Emit()
  private async getArchiveDatas() {
    let nowYear: number = new Date().getFullYear();
    const options: SelectOptions[] = [];
    do {
      const resClosedCourse =  await this.getClosedCourse(nowYear, 1);
      if (resClosedCourse.data.total > 0) {
        options.push({text: nowYear.toString(), value: nowYear.toString()});
      }
      nowYear--;
    } while (nowYear >= 2011);

    this.options = options;
    let year: any = '';
    if (this.$route.query.archives) {
      year = this.$route.query.archives;
    } else {
      year = this.options[0].text;
    }
    this.selectValue = (isNaN(this.queryName)) ? options[0].value : this.queryName;
  }
  private async beforeRouteUpdate(to: Route, from: VueRouter, next: any) {
    const data: any = await getDatas(to);
    this.status = data.status;
    if (data.status === 200) {
      const $to: any = to;
      this.course = data.contentsData.data.courses;
      this.total = data.contentsData.data.total;
      this.queryName = Number($to.query.archives);
      this.selectValue = this.queryName;
      this.title = this.queryName;
      if (isNaN(this.queryName)) {
        store.commit('window/setTitle', '講座一覧 ' + process.env.VUE_APP_TITLE_SUFFIX);
      } else {
        store.commit('window/setTitle', this.title + '年 講座一覧 ' + process.env.VUE_APP_TITLE_SUFFIX);
      }
    } else {
      store.commit('window/setTitle', data.status + ' Error 講座一覧 ' + process.env.VUE_APP_TITLE_SUFFIX);
    }
    next();
  }

  private mounted() {
    this.getArchiveDatas();
  }
}
